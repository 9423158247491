import React, { Component, useEffect, useState } from "react";
import {
	StyleSheet,
	Keyboard,
	Text,
	Image,
	TouchableOpacity,
	SafeAreaView,
	ScrollView,
	Button,
	Dimensions,
	Modal,
	Alert,
	LayoutAnimation,
	View,
	TextInput,
	ActivityIndicator,
} from "react-native";

import { useParams } from "react-router-dom";
// import logoTraq from "../../../assets/images/traq_app.png";

// import AutoHeightImage from "../AutoHeightImageComp";
// import api from './API';
import AsyncStorage from "../../AsyncStorage";
import { APP_COLOURS } from "../../APP_VARS";

import Line from "./ProductItem/Line";
// import Square from "./ProductItem/Square";
// import SquareSmall from "./ProductItem/SquareSmall";
// import ProductSearch from "./ProductSearch";

// import SalesHistory from "./CustomerForm/SalesHistory";
import Notes from "./CustomerForm/Notes";
import ProductSearchCart from "./ProductsCart";
import CustomerProfile from "./CustomerProfile";

const device = {
	width: Dimensions.get("window").width,
	height: Dimensions.get("window").height,
};

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class NewCustomer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userid: "NULL", // set the current user into the AsyncStorage.getItem('current') with the password input
			inter_id: "",

			user_name: "Wesley",
			user_contact: "Cheia",
			user_address: "Address",

			retailer: "retailer",
			mall: "mall",
			loading: true,
			data: [],
			error: null,
			customerProfileModal: true,
			samplesModal: false,
			scanBarcodeModal: false,
			modalCart: false,
			date: "",
			interaction: false,
			samples: [],
			sampledItems: [],
			testers: [],
			barcodes: [],
			isBarcodes: false,
			// sampled: require("./../assets/images/plus_sample.png"),

			// emoji: require("./../assets/images/new_interaction.png"),
			emoji_int: "2",
			hair: "",
			hairbackimage: "",
			hair_colour: "",
			idcount: 0,
			count: 0,

			cartItems: [],
			sampledItems: [],

			productSearch: false,
			showCart: false,

			page: "Customer",

			updated: false,

			customerData: {
				last_name: "",
				first_name: "",
				user_contact: "",
				user_address: "",
				agerange: "",
				retailer: "",
				mall: "",

				cell: { number: "" },
				pmoc: "",
				email: {
					address: "",
				},
			},
		};
	}

	getDeviceData = async () => {
		// try {
		// 	const value = await AsyncStorage.getItem("jwt");
		// 	if (value !== null) {
		// 		this.setState({
		// 			jwt: value,
		// 		});
		// 	}
		// } catch (error) {
		// 	// Error retrieving data
		// }
		// try {
		// 	const value = await AsyncStorage.getItem("userid");
		// 	if (value !== null) {
		// 		this.setState({
		// 			userid: value,
		// 		});
		// 	}
		// } catch (error) {
		// 	// Error retrieving data
		// }
		// try {
		// 	const value = await AsyncStorage.getItem("app_properties");
		// 	if (value !== null) {
		// 		var json = JSON.parse(value);
		// 		this.setState({
		// 			app_properties: json,
		// 			backgroundcolour: json.backgroundcolour,
		// 			brandcolour: json.brandcolour,
		// 			fontcolour: json.fontcolour,
		// 			logo: json.logo,
		// 		});
		// 	}
		// } catch (error) {
		// 	// Error retrieving data
		// }
	};

	componentDidMount() {
		this.getDeviceData();

		// this.setState({
		// 	customerData: this.props.customerData,
		// });
	}

	qrScanned = (barcode) => {
		const count = this.state.count;
		const idcount = this.state.idcount;
		const barcodes = this.state.barcodes.slice(0);

		barcodes.push({
			// pushes new items to an array
			id: idcount,
			barcode: barcode,
			count: count + 1,
		});

		this.setState({
			scanBarcodeModal: false,
			idcount: idcount + 1,
			count: count + 1,
			barcodes: barcodes,
			isBarcodes: true,
		});
	};

	// getData(val) {
	// 	this.setState({
	// 		value: val,
	// 		sampled: require("./../assets/images/barcode_scanner.png"),
	// 	});
	// }

	render() {
		let fontcolour = this.state.fontcolour;
		return (
			<View
				style={{
					flex: 1,
					backgroundColor: APP_COLOURS.BG,
					width: window.innerWidth,
					height: window.innerHeight,
					alignItems: "center",
				}}>
				<View
					style={{
						width: "100%",
						// backgroundColor: APP_COLOURS.DESELECTEDCOLOR,
						padding: 5,
						flexDirection: "row",
						// marginBottom: 20,
						// display: this.state.customerData !== '' ? 'flex' : 'none',
						display: this.state.updated ? "flex" : "none",
						opacity: !this.state.modalCart ? 1 : 0,
					}}>
					{/* {} */}
					{["Customer", "Notes", "History", "Sale"].map((d) => (
						<TouchableOpacity
							style={{
								flex: 1,
							}}
							onPress={() => {
								this.setState({
									page: d,
								});
							}}>
							<View
								style={{
									padding: 10,
									borderRadius: 10,
									paddingVertical: 20,
									marginHorizontal: 2,
									backgroundColor:
										this.state.page === d
											? APP_COLOURS.BLUE2
											: APP_COLOURS.BLACK,
									justifyContent: "center",
									alignItems: "center",
									borderBottomColor: APP_COLOURS.BLACK,
								}}>
								<Text
									style={{
										// color: this.state.page === d ? '#FFF' : APP_COLOURS.BLACK,
										color: "#FFF",
										fontWeight: "300",
									}}>
									{d}
								</Text>
							</View>
						</TouchableOpacity>
					))}
				</View>

				<View
					style={{
						flex: 1,
						backgroundColor: APP_COLOURS.BG,
						width: "100%",
						alignItems: "center",
					}}>
					<View
						style={{
							flex: 1,
							width: "100%",
							maxWidth: 550,
						}}>
						{this.state.page === "Customer" ? (
							<CustomerProfile
								customerData={this.state.customerData}
								user_data={JSON.parse(
									localStorage.getItem("user_data"),
								)}
								isnewCustomer={true}
								updateCustomer={(d) => {
									console.log("customerData", d);

									this.setState({
										customerData: d,
										page: "Sale",
										updated: true,
									});
								}}
								user_id={this.props.user_id}
							/>
						) : null}

						{this.state.page === "Notes" &&
						this.state.customerData !== "" ? (
							<View
								style={{
									flex: 1,
								}}>
								<Notes
									customerData={this.state.customerData}
									user_data={JSON.parse(
										localStorage.getItem("user_data"),
									)}
								/>
							</View>
						) : null}

						{/* {this.state.page === "History" &&
						this.state.customerData !== "" ? (
							<View
								style={{
									flex: 1,
								}}>
								<SalesHistory
									customerData={this.state.customerData}
								/>
							</View>
						) : null} */}

						{this.state.cartItems.length > 0 &&
						this.state.interaction &&
						this.state.customerData !== "" ? (
							<View
								style={{
									height: 70,
								}}
							/>
						) : null}

						{this.state.page === "Sale" &&
						this.state.customerData !== "" ? (
							<ProductSearchCart
								customerData={this.state.customerData}
								user_data={JSON.parse(
									localStorage.getItem("user_data"),
								)}
								isnewCustomer={true}
								user_id={this.props.user_id}
							/>
						) : null}
					</View>
				</View>

				<Modal
					animationType={"fade"}
					transparent={true}
					visible={this.state.samplesModal}
					onRequestClose={() => {
						console.log("samplesModal has been closed.");
					}}>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "white",
							height: this.state.screenHeight - 100,
							width: this.state.screenWidth,
							borderRadius: 10,
							borderWidth: 1,
							borderColor: "#fff",
							marginTop: 50,

							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 10,
							},
							shadowOpacity: 0.23,
							shadowRadius: 13.97,
							elevation: 21,
						}}>
						<View
							style={{
								width: "100%",
								height: "100%",
								// alignItems: 'center',
								// flexDirection: 'column',
								padding: 10,
							}}>
							<Text
								style={{
									fontWeight: "600",
									marginTop: 16,
									fontSize: 20,
								}}>
								Sample Products
							</Text>

							{/* <BluetoothProduct data={this.state.data} /> */}
							<View
								style={{
									flex: 1,
									width: "100%",
								}}>
								<ScrollView>
									<View
										style={{
											height: 20,
										}}
									/>

									<View
										style={{
											flex: 1,
											// paddingLeft: 10,
											// backgroundColor: APP_COLOURS.BACKGROUND_FADE,
										}}>
										{this.state.loading ||
										this.state.loadingPurchases ? (
											<View
												style={{
													flex: 1,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<ActivityIndicator />
											</View>
										) : null}

										<View
											style={{
												flex: 1,
												flexDirection:
													this.state.orientation,
												flexWrap: "wrap",
												display: this.state
													.loadingPurchases
													? "none"
													: "flex",
											}}>
											{this.state.samples
												.sort(
													(a, b) => a.title > b.title,
												)
												.map((d, i) => {
													let id = d.id;
													let dataa =
														this.state.sampledItems.filter(
															(cart) =>
																cart.id == id,
														);

													let incart =
														dataa.length > 0;

													return (
														<Line
															key={i}
															data={d}
															addToCart={(d) =>
																this.addToSamples(
																	d,
																)
															}
															incart={incart}
															purchased={false}
														/>
													);
												})}
										</View>
										<View
											style={{
												height: 90,
											}}
										/>
									</View>
								</ScrollView>
							</View>

							<TouchableOpacity
								style={{
									width: "100%",
									marginTop: 50,
								}}
								onPress={() => {
									this.setState({ samplesModal: false });
									// this.makeRemoteRequest();
								}}>
								<View
									style={{
										padding: 10,
										backgroundColor: APP_COLOURS.BLACK,
										borderRadius: 12,
										width: "100%",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontWeight: "600",
											fontSize: 15,
											color: "#FFF",
										}}>
										Update
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</View>
				</Modal>

				<Modal
					animationType={"fade"}
					transparent={true}
					visible={this.state.scanBarcodeModal}
					onRequestClose={() => {
						console.log("samplesModal has been closed.");
					}}>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "transparent",
							height: device.width,
							width: device.width,
							maxHeight: 400,
							maxWidth: 400,
							borderRadius: 35,
							// borderWidth: 1,
							// borderColor: '#fff',
							marginTop: device.height * 0.25,
							marginLeft: "auto",
							marginRight: "auto",
							maxWidth: 487,
							maxHeight: 385,
							padding: 15,

							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 10,
							},
							shadowOpacity: 0.23,
							shadowRadius: 13.97,
							elevation: 21,
						}}>
						{/* <MaskedView
              maskElement={
                <View
                  style={{
                    height: device.width - 40,
                    width: device.width - 40,
                    borderRadius: 35,
                    backgroundColor: 'white',
                    maxWidth: 400,
                    maxHeight: 400,
                  }}
                />
              }>
              <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <BarcodeScanner
                  width={device.width - 40}
                  height={device.width - 40}
                  maxWidth={400}
                  maxHeight={400}
                  borderRadius={35}
                  padding={0}
                  qrcode={this.qrScanned} // data from child
                />
              </View>
            </MaskedView> */}

						<Image
							source={require("../../../assets/images/qrCode.png")}
							style={{
								resizeMode: "contain",
								height: device.width - 40,
								width: device.width - 40,
								position: "absolute",
								top: 0,
							}}
						/>

						<TouchableOpacity
							onPress={() => {
								this.qrScanned("19834791834890");
							}}>
							<Image
								source={require("../../../assets/images/plus.png")}
								style={{
									resizeMode: "contain",
									width: 39,
									height: 39,
								}}
							/>
						</TouchableOpacity>

						<TouchableOpacity
							style={{
								width: 25,
								height: 25,
								position: "absolute",
								top: 16,
								right: 30,
							}}
							onPress={() => {
								this.setState({ scanBarcodeModal: false });
							}}>
							<Image
								source={require("../../../assets/images/close.png")}
								style={{
									resizeMode: "contain",
									width: 25,
									height: 25,
								}}
							/>
						</TouchableOpacity>
					</View>
				</Modal>

				<Modal
					animationType={"fade"}
					transparent={true}
					visible={this.state.modalCart}
					onRequestClose={() => {
						console.log("samplesModal has been closed.");
					}}>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "white",
							height: device.height * 0.5,
							width: device.width - 40,
							borderRadius: 10,
							borderWidth: 1,
							borderColor: "#fff",
							marginTop: 20,
							marginLeft: 20,
							marginRight: 20,

							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 10,
							},
							shadowOpacity: 0.23,
							shadowRadius: 13.97,
							elevation: 21,
						}}>
						<View
							style={{
								width: "100%",
								height: "100%",
								alignItems: "center",
								flexDirection: "column",
							}}>
							<Text
								style={{
									fontWeight: "600",
									marginTop: 16,
									fontSize: 20,
								}}>
								Sampled Products
							</Text>

							{/* <BluetoothProduct data={this.state.data}/> */}

							<TouchableOpacity
								onPress={() => {
									this.setState({ samplesModal: false });
									// this.makeRemoteRequest();
								}}>
								{/* <Image
									source={require("../../../assets/images/interaction_update.png")}
									style={{
										resizeMode: "contain",
										width: 228,
										height: 41,
										bottom: 10,
									}}
								/> */}
							</TouchableOpacity>
						</View>
					</View>
				</Modal>

				<Modal
					animationType={"fade"}
					transparent={true}
					visible={this.state.productSearch}
					onRequestClose={() => {
						console.log("samplesModal has been closed.");
					}}>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#FFF",
							height: this.state.screenHeight,
							width: this.state.screenWidth,
							borderRadius: 10,
							// padding: 10,
							paddingBottom: 40,
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 10,
							},
							shadowOpacity: 0.23,
							shadowRadius: 13.97,
							elevation: 21,
						}}>
						<View
							style={{
								width: "100%",
								height: "100%",
								alignItems: "center",
								flexDirection: "column",
								paddingTop: 50,
							}}>
							<Text
								style={{
									fontWeight: "600",
									marginTop: 16,
									fontSize: 20,
								}}>
								Product search
							</Text>

							{/* <ProductSearch
								cartItems={this.state.cartItems}
								close={() =>
									this.setState({
										productSearch: false,
									})
								}
								addToCart={async (d) => {
									this.addToCart(d);
									this.setState({
										productSearch:
											!this.state.productSearch,
									});
								}}
							/> */}

							{/* <TouchableOpacity
                style={{
                  width: '100%',
                  marginTop: 50,
                  padding: 10,
                }}
                onPress={() => {
                  this.setState({
                    productSearch: !this.state.productSearch,
                  });
                }}>
                <View
                  style={{
                    padding: 10,
                    backgroundColor: APP_COLOURS.BLACK,
                    borderRadius: 12,
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Text
                    allowFontScaling={false}
                    style={{
                      fontWeight: '600',
                      fontSize: 15,
                      color: '#FFF',
                    }}>
                    Update
                  </Text>
                </View>
              </TouchableOpacity> */}
						</View>
					</View>
				</Modal>
			</View>
		);
	}

	addToCart = async (d) => {
		let exists = await this.state.cartItems.filter(
			(cart) => cart.id == d.id,
		);
		let cartItems = "";
		if (exists.length > 0) {
			cartItems = await this.state.cartItems.filter(
				(cart) => cart.id !== d.id,
			);
			await this.setState({
				cartItems: cartItems,
			});
		} else {
			cartItems = await [...this.state.cartItems, d];
			await this.setState({
				cartItems: cartItems,
			});
		}

		if (cartItems.length == 0) {
			await this.setState({
				showCart: false,
			});
		}
		// LayoutAnimation.configureNext(CustomLayoutSpring);
	};

	addToSamples = async (d) => {
		let exists = await this.state.sampledItems.filter(
			(cart) => cart.id == d.id,
		);
		let sampledItems = "";
		if (exists.length > 0) {
			sampledItems = await this.state.sampledItems.filter(
				(cart) => cart.id !== d.id,
			);
			await this.setState({
				sampledItems: sampledItems,
			});
		} else {
			sampledItems = await [...this.state.sampledItems, d];
			await this.setState({
				sampledItems: sampledItems,
			});
		}

		if (sampledItems.length == 0) {
			await this.setState({
				showCart: false,
			});
		}
		// LayoutAnimation.configureNext(CustomLayoutSpring);
	};
}

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		const params = useParams();
		const { user_id, token } = params;
		useEffect(() => {
			const fetchUserData = async (token) => {
				try {
					const response = await fetch(
						`https://workflow.tetrice.co.za/webhook/79d646ab-dd9b-4410-a905-62b6694f65c8`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`, // Set Bearer token in the Authorization header
							},
							body: JSON.stringify({
								user_id: user_id,
							}),
						},
					);

					if (!response.ok) {
						throw new Error("Failed to fetch user data");
					}

					const userData = await response.json();

					// Set user data in localStorage
					localStorage.setItem("user_data", JSON.stringify(userData));
				} catch (error) {
					console.error("Error fetching user data:", error);
				}
			};

			if (user_id && token) {
				localStorage.setItem("user_id", user_id);
				localStorage.setItem("accessToken", token);

				// Fetch user data using the Bearer token
				fetchUserData(token);
			}
		}, [params]);

		return <Component {...props} params={params} user_id={user_id} />;
	}

	return ComponentWithRouterProp;
}
export default withRouter(NewCustomer);

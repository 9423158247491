// src/QrScanner.js
import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";

const QrScanner = () => {
	const [data, setData] = useState("No result");

	return (
		<View
			style={{
				width: window.innerWidth,
				height: window.innerHeight,
			}}>
			<QrReader
				constraints={{ facingMode: "environment" }}
				onResult={(result, error) => {
					if (!!result) {
						setData(result?.text);
						// Optionally, send data to React Native app
						window.ReactNativeWebView &&
							window.ReactNativeWebView.postMessage(result.text);
					}

					if (!!error) {
						console.info(error);
					}
				}}
				containerStyle={{
					width: window.innerWidth,
					height: window.innerHeight,
				}} // Adjust size as needed
			/>
		</View>
	);
};

export default QrScanner;
